import React, {useEffect, useState, useRef, useCallback} from 'react';
import styled from 'styled-components';
import API from '../../ApiBlog';
import BlogTable from './BlogTable';
import {Select, message, Radio} from 'antd';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../../errors';
import {navigate} from 'gatsby';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {Add} from '@styled-icons/material/Add';
import getOptions from '../../Utils/getOptions';
const appConfig = require('../../data.json');

const DOCUMENT_NAME = 'Article_Default';
const TYPES = {
  ...(!appConfig.isLiteVersion && {blog: '部落格文章'}),
  faq: '常見問題',
  draft: '草稿文章',
};

const initialLabels = [{value: '', label: '所有文章'}];
const UISTATE = {
  LIST: 'LIST',
  DETAIL: 'DETAIL',
};

export default function BlogList(props) {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    type: Object.keys(TYPES)[0],
    label: '',
  });
  const [records, setRecords] = useState([]);
  const [token] = useOutlet('jwtToken');
  const [actions] = useOutlet('actions');
  const [uiState, setUiState] = useState(UISTATE.LIST);
  const [selected, setSelected] = useState();

  const api = useRef(new API()).current;
  const [options, setOptions] = useState({blog: [], faq: [], draft: []});

  const getRecords = useCallback(async () => {
    if (api.apiHost) {
      setLoading(true);

      let params = {};
      if (filters.type === 'blog') {
        params = {
          $and: [
            {label: {$not: {$regex: 'FAQ'}}},
            {label: {$not: {$regex: 'draft'}}},
          ],
        };
      } else if (filters.type === 'faq') {
        params = {
          $and: [{label: {$regex: 'FAQ'}}, {label: {$not: {$regex: 'draft'}}}],
        };
      } else if (filters.type === 'draft') {
        params = {
          $and: [{label: {$regex: 'draft'}}],
        };
      }

      if (filters.label) {
        params['$and'] = [...params['$and'], {label: {$regex: filters.label}}];
      }

      try {
        const resp = await api.getBlogs({
          documentName: DOCUMENT_NAME,
          ...params,
        });
        setRecords(resp);
      } catch (ex) {
        console.warn(ex);
      }

      setLoading(false);
    }
  }, [filters, api.apiHost]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  // initial
  useEffect(() => {
    (async () => {
      setLoading(true);

      // get jwt token
      await actions.getMicroServiceToken();

      // get labels
      try {
        let resp = await api.getBlogLabels();
        resp = resp && resp.length ? resp[0].children : [];
        let options_blog = [
          ...initialLabels,
          ...resp.map((x) => ({value: x, label: x})),
        ];

        resp = await api.getFaqLabels();
        resp = resp && resp.length ? resp[0].children : [];
        let options_faq = [
          ...initialLabels,
          ...resp.map((x) => ({value: x.name, label: x.name})),
        ];
        setOptions({
          blog: options_blog,
          faq: options_faq,
          draft: [],
        });
      } catch (err) {
        console.warn(err);
      }
    })();
  }, []);

  const onItemClick = async (record) => {
    navigate(`/blog/${record ? '?id=' + record.id : ''}`);
    // window.open(
    //   `${appConfig.endpoint.revBlogDashboardUrl}/article/editor?token=${token}${
    //     record ? '&id=' + record.id : ''
    //   }`,
    //   '_blank',
    // );
  };

  const onDelete = async (record) => {
    if (window.confirm(`確定要刪除 ${record.title}？`)) {
      setLoading(true);
      try {
        let resp = await api.deleteBlog({
          documentName: DOCUMENT_NAME,
          token,
          id: record.id,
        });
        getRecords();
      } catch (err) {
        errorHandler(err);
        if (err.status === 403) {
          await actions.getMicroServiceToken();
        }
      }
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <h4>文章型別</h4>
            {getOptions(TYPES)
              .filter((x) => x.value !== 'all')
              .map((option, idx) => (
                <Button
                  key={idx}
                  type={filters.type === option.value ? 'primary' : 'default'}
                  onClick={() => {
                    setFilters((prev) => ({
                      ...prev,
                      type: option.value,
                      label: '',
                    }));
                  }}
                  style={{marginRight: 10}}>
                  {option.label}
                </Button>
              ))}
          </div>
          <div className="row">
            <h4>{TYPES[filters.type] + '類別'}</h4>
            <Select
              value={filters.label}
              onChange={(value) =>
                setFilters((prev) => ({...prev, label: value}))
              }>
              {options[filters.type].map((option, idx) => (
                <Select.Option key={idx} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>

            <div style={{flex: 1}} />
            <Button
              type="primary"
              onClick={getRecords}
              icon={
                <Refresh color="#fff" size={20} style={{marginRight: 5}} />
              }>
              刷新
            </Button>
          </div>
        </div>
      </Block>
      <Block>
        <div className="row" style={{margin: 0}}>
          <h2>
            {TYPES[filters.type]} /{' '}
            {
              options[filters.type].reduce((sum, x) => {
                sum[x.value] = x.label;
                return sum;
              }, {})[filters.label]
            }
          </h2>
          <div style={{flex: 1}} />
          <Button
            onClick={() => {
              onItemClick();
              // setSelected(null);
              // setUiState(UISTATE.DETAIL);
            }}
            // loading={creating}
            type="primary"
            color={appConfig.colors.sub}>
            <Add color="#fff" size={20} style={{marginRight: 5}} />
            新增
          </Button>
        </div>
        <div>
          <BlogTable
            records={records}
            loading={loading}
            onItemClick={onItemClick}
            // onItemClick={record => {
            //   setSelected(record);
            //   setUiState(UISTATE.DETAIL);
            // }}
            onDelete={onDelete}
          />
        </div>
      </Block>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
