const config = require("./data.json");

class ApiFactory {
  constructor() {
    // if (!serviceHost) {
    //   throw "MISSING REQUIRED PARAMS: Blog Api Facotry needs serviceHost";
    // }
    // if (!client) {
    //   throw "MISSING REQUIRED PARAMS: Blog Api Facotry needs client";
    // }

    this.apiHost = config.endpoint.revJstorageHost;
    // this.serviceHost = serviceHost;
    this.client = config.client;
  }

  // getService = async () => {
  //   const resp = await fetch(`${this.serviceHost}?client_id=${this.client}`);

  //   if (resp.status.toString()[0] !== "2") {
  //     throw new Error(resp);
  //   }
  //   let services = await resp.json();

  //   // copy jsonstore to articles
  //   let service = services.find(sv => sv.service_name === "jstorage");
  //   service = {
  //     ...service,
  //     service_name: "articles",
  //     display_name: "Revtel-Article-Service"
  //   };

  //   this.apiHost = `${service.domain}/${service.latest}`;
  // };

  getBlogLabels = async () => {
    let url = `${this.apiHost}/document/categories/find?client_id=${this.client}`;

    let data = {
      query: {
        name: "articles"
      }
    };

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    if (resp.status.toString()[0] !== "2") {
      throw new Error(resp);
    }
    return await resp.json();
  };

  getFaqLabels = async () => {
    let url = `${this.apiHost}/document/categories/find?client_id=${this.client}`;

    let data = {
      query: {
        name: "faqs"
      }
    };

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    if (resp.status.toString()[0] !== "2") {
      throw new Error(resp);
    }
    return await resp.json();
  };

  getBlogs = async ({ documentName, ...params }) => {
    let url = `${this.apiHost}/document/${documentName}/find?client_id=${this.client}`;

    let data = {
      query: params,
      sorting: ["-updated"]
    };

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    if (resp.status.toString()[0] !== "2") {
      throw new Error(resp);
    }
    return await resp.json();
  };

  deleteBlog = async ({ documentName, token, ...params }) => {
    let url = `${this.apiHost}/document/${documentName}/find-one/delete?token=${token}`;

    let data = {
      query: params
    };

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    if (resp.status.toString()[0] !== "2") {
      throw new Error(resp);
    }
    return await resp.json();
  };
}

export default ApiFactory;
